// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.information-container {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: rgb(211, 203, 203);
  min-height: 100vh;
  height: max-content;
}
#message {
  margin-left: 20px;
}
h2 {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.778);
}
#content {
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
  white-space: pre-wrap;
  margin-left: 20px;
}

.new-information {
  padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Information/Information.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,oCAAoC;EACpC,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".information-container {\n  font-family: Arial, sans-serif;\n  margin: 0;\n  padding: 0;\n  background-color: rgb(211, 203, 203);\n  min-height: 100vh;\n  height: max-content;\n}\n#message {\n  margin-left: 20px;\n}\nh2 {\n  margin-left: 10px;\n  color: rgba(0, 0, 0, 0.778);\n}\n#content {\n  color: rgba(0, 0, 0, 0.9);\n  font-size: 18px;\n  white-space: pre-wrap;\n  margin-left: 20px;\n}\n\n.new-information {\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
