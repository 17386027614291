import React from "react";
import "./States.css";

function States({ states }) {
  return (
    <div className="states-container">
      <ul className="states">
        {Object.entries(states).map(([state, goals]) => (
          <li key={state}>
            <strong>{state}:</strong>
            <ul>
              {goals &&
                Object.entries(goals).map(([goal, value]) => (
                  <li key={goal} className={value === 1 ? "green-goal" : ""}>
                    {goal}
                  </li>
                ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default States;
