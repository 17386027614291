import axios from "axios";
import Cookies from "js-cookie";
const baseURL = "https://pbot.leastcube.com/api";
// const baseURL = "http://localhost:8004/api";
const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

const apiEndpoints = {
  getCoachData: "/patient",
  postUserMessage: "/patient",
  saveTranscription: "/transcription/save",
  transcriptionsIds: "/transcription/ids",
  getTranscription: "/transcription/get_transcription",
  filtering: "/transcription/filtering/",
  memberInfo: "/bot/information",
  updateInformation: "/bot/information/update",
  updatePersona: "/bot/information/update/persona",
  updateBackstory: "/bot/information/update/backsotry",
  coachCount: "/transcription/coach_count",
  summarization: "/transcription/filtering",
};

export const fetchCoachData = async (username, password) => {
  try {
    const response = await api.get(apiEndpoints.getCoachData, {
      auth: { username: username, password: password },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching coach data:", error);
    throw error;
  }
};

export const postUserMessage = async (message, member, newInformation) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  try {
    const response = await api.post(
      apiEndpoints.postUserMessage,
      {
        content: message,
        member: member,
        newInformation: newInformation,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: username,
          password: password,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error posting user message:", error);
    throw error;
  }
};

export const saveTranscription = async (messages, member, score, coachName) => {
  try {
    const username = Cookies.get("username");
    const password = Cookies.get("password");
    const response = await api.post(
      apiEndpoints.saveTranscription,
      {
        content: messages,
        member: member,
        score: score,
        coachName: coachName,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        auth: {
          username: username,
          password: password,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving transcription:", error);
    throw error;
  }
};

export const getTransctriptionIds = async (username, password) => {
  try {
    const response = await api.post(
      apiEndpoints.transcriptionsIds,
      {},
      {
        auth: {
          username: username,
          password: password,
        },
      }
    );
    return response;
  } catch (err) {
    console.error("Error getting transcription IDs:", err);
    throw err;
  }
};

export const getTranscription = async (id) => {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  try {
    const response = await fetch(`${baseURL}${apiEndpoints.getTranscription}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error getting transcription IDs:", err);
    throw err;
  }
};

export const filtering = async (textInput, username, password) => {
  const response = await fetch(`${baseURL}${apiEndpoints.filtering}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
    body: JSON.stringify({ text: textInput }),
  });
  return response;
};

export const getMemberInfo = async (name, username, password) => {
  try {
    const response = await api.post(
      apiEndpoints.memberInfo,
      { member_name: name },
      {
        auth: {
          username: username,
          password: password,
        },
      }
    );
    return response;
  } catch (err) {
    console.error("Error getting member information:", err);
    throw err;
  }
};

export const submitInformation = async (name, content, username, password) => {
  const res = await fetch(`${baseURL}${apiEndpoints.updateInformation}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
    body: JSON.stringify({
      name: name,
      content: content,
    }),
  });
  return res;
};

export const getCoachCount = async (username, password) => {
  try {
    const response = await api.post(apiEndpoints.coachCount, {
      auth: {
        username: username,
        password: password,
      },
    });
    return response;
  } catch (err) {
    console.error("Error getting coach counts:", err);
    throw err;
  }
};

export const updatePersona = async (name, content, username, password) => {
  const res = await fetch(`${baseURL}${apiEndpoints.updatePersona}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
    body: JSON.stringify({
      name: name,
      content: content,
    }),
  });
  return res;
};

export const updateBackstory = async (name, content, username, password) => {
  const res = await fetch(`${baseURL}${apiEndpoints.updateBackstory}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
    body: JSON.stringify({
      name: name,
      content: content,
    }),
  });
  return res;
};

export const summarization = async (text, username, password) => {
  const data = {
    text: text,
    filtration: false,
    summarization: true,
  };

  const res = await api.post(apiEndpoints.summarization, data, {
    auth: {
      username: username,
      password: password,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res;
};
