import React, { useState } from "react";
import "./Filtering.css";
import { filtering } from "../../API/API";
import Cookies from "js-cookie";

function FilteringComponent() {
  const [textInput, setTextInput] = useState("");
  const [result, setResult] = useState("");
  const [username, setUsername] = useState(Cookies.get("username"));
  const [password, setPassword] = useState(Cookies.get("password"));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setResult("Loading..."); // Add loading message

    try {
      const response = await filtering(textInput, username, password);
      const reader = response.body.getReader();
      let resultString = '';

      // Read the stream and concatenate chunks into a string
      while (true) {
        const { done, value } = await reader.read();

        if (done) break;

        resultString += new TextDecoder().decode(value);
      }

      setResult(resultString);
    } catch (error) {
      console.error("Error:", error);
      setResult("An error occurred.");
    }
  };

  return (
    <div className="filtering-container">
      <h1>Scrubbing Sensitive Information</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
          rows="10"
          placeholder="Type something..."
        ></textarea>
        <br />
        <button
          type="submit"
          className="button"
          style={{ width: "100%", marginTop: "0" }}
        >
          Submit
        </button>
      </form>
      <div
        id="result"
        style={{
          marginTop: "20px",
          padding: "10px",
          border: "1px solid #ddd",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxSizing: "border-box",
          width: "100%",
          whiteSpace: "pre-wrap",
        }}
      >
        {result}
      </div>
    </div>
  );
}

export default FilteringComponent;
