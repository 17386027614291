import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./Information.css";
import Cookies from "js-cookie";
import {
  getMemberInfo,
  submitInformation,
  updateBackstory,
  updatePersona,
} from "../../API/API";
import { TextareaAutosize } from "@mui/material";

function InformationComponent() {
  const [isEditingBackstory, setIsEditingBackstory] = useState(false);
  const [isEditingPersonality, setIsEditingPersonality] = useState(false);
  const [personality, setPersonality] = useState("");
  const [backstory, setBackstory] = useState("");
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState(Cookies.get("username"));
  const [password, setPassword] = useState(Cookies.get("password"));
  const params = useParams();

  const handleSubmitPersonality = async () => {
    try {
      const res = await updatePersona(
        params.name,
        personality,
        username,
        password
      );
      setMessage(res.message);
      setIsEditingPersonality(false);
    } catch (err) {
      setMessage("An error occurred.");
    }
  };

  const handleSubmitBackstory = async () => {
    try {
      const res = await updateBackstory(
        params.name,
        backstory,
        username,
        password
      );
      setMessage(res.message);
      setIsEditingBackstory(false);
    } catch (err) {
      setMessage("An error occurred.");
    }
  };

  const getInfo = async () => {
    try {
      const res = await getMemberInfo(params.name, username, password);
      setBackstory(res.data.info);
      setPersonality(res.data.persona);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);
  console.log(personality);
  return (
    <div className="information-container">
      <h2 style={{ textAlign: "left", padding: "16px" }}>{params.name}</h2>
      <div id="message">{message}</div>
      <h2 style={{ textAlign: "left" }}>Personality</h2>
      {isEditingPersonality ? (
        <>
          <button
            className="button"
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            onClick={handleSubmitPersonality}
          >
            Submit
          </button>
          <TextareaAutosize
            value={personality}
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              width: "-webkit-fill-available",
            }}
            onChange={(e) => setPersonality(e.target.value)}
            rowsMin={5} // Minimum number of rows
          />
        </>
      ) : (
        <>
          <button
            className="button"
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            onClick={() => setIsEditingPersonality(true)}
          >
            Edit
          </button>
          <div id="content">{personality}</div>
        </>
      )}
      <h2 style={{ textAlign: "left", marginTop: "16px" }}>Backstory</h2>
      {isEditingBackstory ? (
        <>
          <button
            className="button"
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            onClick={handleSubmitBackstory}
          >
            Submit
          </button>
          <TextareaAutosize
            value={backstory}
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              width: "-webkit-fill-available",
            }}
            onChange={(e) => setBackstory(e.target.value)}
            rowsMin={5} // Minimum number of rows
          />
        </>
      ) : (
        <>
          <button
            className="button"
            style={{ marginLeft: "20px", marginBottom: "20px" }}
            onClick={() => setIsEditingBackstory(true)}
          >
            Edit
          </button>
          <div id="content">{backstory}</div>
        </>
      )}
      <div className="new-information">
        <h2 style={{ textAlign: "left", margin: "0" }}>New Information</h2>
        <p>{params.new}</p>
      </div>
    </div>
  );
}

export default InformationComponent;
