import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import Modal from "react-modal";
import { fetchCoachData, summarization } from "../../API/API";
import "./Summarization.css";

export default function Summarization() {
  const [username, setUsername] = useState(Cookies.get("username"));
  const [password, setPassword] = useState(Cookies.get("password"));
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [conversation, setConversation] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);

  const textareaRef = useRef(null);

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
      background: "white",
    },
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await fetchData();
  };

  const handleSubmit = async () => {
    try {
      setResponseData(null);
      setLoading(true);
      const res = await summarization(conversation, username, password);
      setResponseData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const res = await fetchCoachData(username, password);
      if (res.message !== "Incorrect credentials") {
        setModalIsOpen(false);
        Cookies.set("username", username);
        Cookies.set("password", password);
      } else {
        setInvalidCredentials(true);
        setModalIsOpen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTextareaInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height based on scroll height
    }
  };

  const renderTextWithNewlines = (text) => {
    return text.split("\n").map((line, index) => <div key={index}>{line}</div>);
  };

  return (
    <div className="summarization-container">
      <Modal isOpen={modalIsOpen} style={modalStyle}>
        <form className="login-form" onSubmit={handleLogin}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {invalidCredentials && (
            <p style={{ color: "red", margin: 0 }}>Invalid credentials</p>
          )}
          <button className="button">Login</button>
        </form>
      </Modal>
      <div className="section">
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
        <textarea
          ref={textareaRef}
          placeholder="Conversation"
          className="conversation-input"
          value={conversation}
          rows={4}
          onChange={(e) => {
            setConversation(e.target.value);
            handleTextareaInput();
          }}
          onInput={handleTextareaInput}
          style={{ resize: "both" }} // Allow resizing
        />
      </div>
      <div className="section">
        <h2>Response Data</h2>
        {loading && <CircularProgress />}
        {responseData && (
          <div className="response-container">
            <div>
              <strong>Data:</strong> {renderTextWithNewlines(responseData.data)}
            </div>
            <div>
              <strong>Assessment:</strong>{" "}
              {renderTextWithNewlines(responseData.assessment)}
            </div>
            <div>
              <strong>Plan:</strong> {renderTextWithNewlines(responseData.plan)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
