// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summarization-container {
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: 32px;
  gap: 8px;
}

.section {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border: 1px solid #dad8d8;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.conversation-input {
  width: 80%;
  overflow: hidden;
}

.response-container {
  width: 100%;
  padding: 16px;
  background-color: #f9f9f9;
}

.response-container h2 {
  margin-bottom: 16px;
}

.response-container div {
  margin-bottom: 8px;
}

@media screen and (max-width: 900px) {
  .summarization-container {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Summarization/Summarization.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;EACR,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".summarization-container {\n  max-width: 100vw;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: stretch;\n  padding: 32px;\n  gap: 8px;\n}\n\n.section {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 8px;\n  padding: 16px;\n  border: 1px solid #dad8d8;\n  border-radius: 10px;\n  background-color: #f9f9f9;\n}\n\n.conversation-input {\n  width: 80%;\n  overflow: hidden;\n}\n\n.response-container {\n  width: 100%;\n  padding: 16px;\n  background-color: #f9f9f9;\n}\n\n.response-container h2 {\n  margin-bottom: 16px;\n}\n\n.response-container div {\n  margin-bottom: 8px;\n}\n\n@media screen and (max-width: 900px) {\n  .summarization-container {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
