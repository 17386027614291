// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filtering-container {
  font-family: "Helvetica Neue", sans-serif;
  color: #333;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  align-content: stretch;
  flex-wrap: wrap;
  flex-direction: column;
}

h1 {
  font-size: 2em;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
}

form {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
}

#result {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  white-space: pre-wrap;
}

textarea {
  width: 100%;
  padding: 10px;
  font-size: 17px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  box-sizing: border-box;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Filtering/Filtering.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,WAAW;EACX,yBAAyB;EACzB,SAAS;EACT,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;EACtB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".filtering-container {\n  font-family: \"Helvetica Neue\", sans-serif;\n  color: #333;\n  background-color: #f9f9f9;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  /* height: 100vh; */\n  align-content: stretch;\n  flex-wrap: wrap;\n  flex-direction: column;\n}\n\nh1 {\n  font-size: 2em;\n  font-weight: 300;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\nform {\n  width: 80%;\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n#result {\n  margin-top: 20px;\n  padding: 10px;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n  background-color: #fff;\n  box-sizing: border-box;\n  width: 100%;\n  white-space: pre-wrap;\n}\n\ntextarea {\n  width: 100%;\n  padding: 10px;\n  font-size: 17px;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n  resize: none;\n  box-sizing: border-box;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
