// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.states-container {
  padding: 20px;
  text-align: start;
  font-size: 15px;
  width: 30%;
}

.green-state {
  color: #a999ff;
}

.green-goal {
  color: #a999ff;
}

.states {
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: calc(100vh - 58px);
  overflow-y: auto;
  padding-right: 10px;
}

#dark .states {
  background-color: #49555c17;
  /* background: transparent; */
  color: rgb(211, 203, 203);
}

#light .states {
  background-color: #dad8d8;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/Components/States/States.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;EACpB,8BAA8B;EAC9B,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,6BAA6B;EAC7B,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".states-container {\n  padding: 20px;\n  text-align: start;\n  font-size: 15px;\n  width: 30%;\n}\n\n.green-state {\n  color: #a999ff;\n}\n\n.green-goal {\n  color: #a999ff;\n}\n\n.states {\n  border-radius: 10px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  max-height: calc(100vh - 58px);\n  overflow-y: auto;\n  padding-right: 10px;\n}\n\n#dark .states {\n  background-color: #49555c17;\n  /* background: transparent; */\n  color: rgb(211, 203, 203);\n}\n\n#light .states {\n  background-color: #dad8d8;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
