import React, { useState, useEffect } from "react";
import "./Transcription.css";
import {
  getCoachCount,
  getTranscription,
  getTransctriptionIds,
} from "../../API/API";
import Cookies from "js-cookie";
import Modal from "react-modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function TranscriptionComponent() {
  const [transcriptionIds, setTranscriptionIds] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [originalTranscription, setOriginalTranscription] = useState("");
  const [filteredTranscription, setFilteredTranscription] = useState("");
  const [username, setUsername] = useState(Cookies.get("username"));
  const [password, setPassword] = useState(Cookies.get("password"));
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [coachCount, setCoachCount] = useState({});
  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
      background: "white",
    },
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await fetchIds();
  };

  useEffect(() => {
    fetchIds();
    fetchCounts();
  }, []);

  const fetchCounts = async () => {
    try {
      const res = await getCoachCount();
      setCoachCount(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchIds = async () => {
    try {
      const res = await getTransctriptionIds(username, password);
      if (res.data.message !== "Incorrect credentials") {
        setModalIsOpen(false);
        setTranscriptionIds(res.data);
        Cookies.set("username", username);
        Cookies.set("password", password);
      } else {
        setInvalidCredentials(true);
        setModalIsOpen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const allKeys = Object.values(coachCount).flatMap(Object.keys);
  const uniqueKeys = Array.from(new Set(allKeys));

  const transposedData = uniqueKeys.map((key) => ({
    name: key,
    ...Object.fromEntries(
      Object.entries(coachCount).map(([name, data]) => [name, data[key] || "-"])
    ),
  }));

  const coachCountList = () => {
    const items = Object.entries(coachCount).map(
      ([name, value]) => `${name}: ${value}`
    );

    return (
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedId(selectedValue);
    fetchTranscription(selectedValue);
  };

  const fetchTranscription = async (id) => {
    try {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const res = await getTranscription(id, username, password);
      setOriginalTranscription(
        res.original
          .replace(/Coach:/g, '<span style="color: green;">Coach:</span>')
          .replace(/Member:/g, '<span style="color: blue;">Member:</span>')
      );
      setFilteredTranscription(
        res.filtered
          .replace(/Coach:/g, '<span style="color: green;">Coach:</span>')
          .replace(/Member:/g, '<span style="color: blue;">Member:</span>')
          .replace(/SENSITIVE/g, '<span style="color: red;">SENSITIVE</span>')
      );
    } catch (err) {
      console.error("error getting transcription: ", err);
    }
  };

  const handleSearchButtonClick = () => {
    fetchTranscription(searchInput);
  };

  return (
    <div className="transcription-container">
      <Modal style={modalStyle} isOpen={modalIsOpen}>
        <form className="login-form" onSubmit={handleLogin}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {invalidCredentials && (
            <p style={{ color: "red", margin: 0 }}>Invalid credentials</p>
          )}
          <button className="button">Login</button>
        </form>
      </Modal>
      <div id="search_container">
        <input
          id="search_input"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <button
          id="search_button"
          style={{ margin: "0" }}
          className="button"
          onClick={handleSearchButtonClick}
        >
          Search
        </button>
      </div>
      <select
        id="dropdown"
        className="dropdown"
        value={selectedId}
        onChange={handleDropdownChange}
      >
        {transcriptionIds.length > 0 &&
          transcriptionIds?.map((id) => (
            <option key={id} value={id}>
              {id}
            </option>
          ))}
      </select>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Life Event</TableCell>
              {Object.keys(coachCount).map((name) => (
                <TableCell key={name} align="right">
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transposedData.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                {Object.values(row)
                  .slice(1)
                  .map((value, index) => (
                    <TableCell key={index} align="right">
                      {value}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div className="coach-count-container">{coachCountList()}</div> */}
      <div className="content">
        <div className="text">
          <h2>Original</h2>
          <div
            id="original"
            dangerouslySetInnerHTML={{ __html: originalTranscription }}
          ></div>
        </div>
        <div className="text">
          <h2>Filtered</h2>
          <div
            id="filtered"
            dangerouslySetInnerHTML={{ __html: filteredTranscription }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default TranscriptionComponent;
