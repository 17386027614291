import React, { createContext, useState } from "react";
import "./App.css";
import Chatbox from "./Components/Chatbox/Chatbox";
import Information from "./Components/Information/Information";
import States from "./Components/States/States";
import ReactSwitch from "react-switch";
import { ToastContainer } from "react-toastify";
import { Navigate, Route, Routes } from "react-router-dom";
import TranscriptionComponent from "./Components/Transcription/Transcription";
import FilteringComponent from "./Components/Filtering/Filtering";
import InformationComponent from "./Components/Information/Information";
import Summarization from "./Components/Summarization/Summarization";

export const ThemeContext = createContext(null);
function App() {
  const [theme, setTheme] = useState("light");
  const [type, setType] = useState();
  const [version, setVersion] = useState();
  const [information, setInformation] = useState();
  const [states, setStates] = useState();
  const [averageResponseTime, setAverageResponseTime] = useState(null);
  const toggleTheme = () => {
    setTheme((curTheme) => (curTheme === "dark" ? "light" : "dark"));
  };
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <Routes>
        <Route path="/" element={<Navigate to="/chat" />} />
        <Route
          path="/chat"
          element={
            <div className="App" id={theme}>
              <div className="app-container">
                {/* {states && <States states={states} />} */}
                <div className="chatbox-container">
                  <h2>
                    {type}-v{version}
                  </h2>
                  <Chatbox
                    setType={setType}
                    setVersion={setVersion}
                    // setInformation={setInformation}
                    // setStates={setStates}
                    setAverageResponseTime={setAverageResponseTime}
                  />
                </div>
              </div>
            </div>
          }
        />
        <Route path="/transcription" element={<TranscriptionComponent />} />
        <Route path="/filtering" element={<FilteringComponent />} />
        <Route
          path="/information/:name/:new"
          element={<InformationComponent />}
        />
        <Route path="/summarization" element={<Summarization />} />
      </Routes>
      <ToastContainer />
    </ThemeContext.Provider>
  );
}

export default App;
