import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SpeechPlayer } from "openai-speech-stream-player";
import React, { useEffect, useRef, useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Modal from 'react-modal';
import { fetchCoachData, postUserMessage, saveTranscription } from "../../API/API";
import { makeNotif } from "../Toastify";
import "./Chatbox.css";
import "./VoiceRecorder.css";
import  Cookies from "js-cookie";
function Chatbox({
  setType,
  setVersion,
  setInformation,
  setStates,
  setAverageResponseTime,
}) {
  const [response, setResponse] = useState();
  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [recording, setRecording] = useState(false);
  const [responseTimes, setResponseTimes] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const audioRef = useRef(null);
  const playerRef = useRef(null);
  const [selectedVoice, setSelectedVoice] = useState("");
  const [newInformation, setNewInformation] = useState("");
  const femaleVoices = ["alloy", "fable", "nova", "shimmer"];
  const maleVoices = ["onyx", "echo"]
  const [score, setScore] = useState(null);
  const [members, setMembers] = useState([]);
  const [selectedLifeEvent, setSelectedLifeEvent] = useState("");
  const [lifeEvents, setLifeEvents] = useState([]);
  const [selectedMember, setSelectedMember] = useState("")
  const [IDs, setIDs] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [username, setUsername] = useState(Cookies.get("username"));
  const [password, setPassword] = useState(Cookies.get("password"));
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [coachNames, setCoachNames] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState("");
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
    },
  };

  const handleRadioChange = (event) => {
    setScore(event.target.value);
  };

  const handleLifeEventChange = (event) => {
    setSelectedLifeEvent(event.target.value);
    setSelectedMember(members[event.target.value][0].name);
    setScore(null);
    setMessages([]);
  };

  const handleMemberChange = (event) => {
    setSelectedMember(event.target.value);
    setScore(null);
    setMessages([]);
  };

  const handleMicrophoneAccess = (e) => {
    makeNotif("info", "Give microphone access to the browser first");
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true }).catch((e) => console.error(e));
    } else {
      makeNotif("info", "Audio recording not supported in this browser")
    }
  }

  const getCurrentTime = () => {
    const currentDate = new Date();
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const calculateAverageResponseTime = () => {
    if (responseTimes.length > 0) {
      const sumResponseTimes = responseTimes.reduce(
        (acc, time) => acc + time,
        0
      );
      const average = (sumResponseTimes / responseTimes?.length) / 1000;

      setAverageResponseTime(average.toFixed(1)); // Keep two decimal places
    }
  };

  const newArray = messages.map(obj => {
    const { direction, html, type, ...rest } = obj;
    return rest;
  });

  function searchByName(name) {
    const flatArray = [].concat(...Object.values(members));
    for (let i = 0; i < flatArray?.length; i++) {
        if (flatArray[i].name === name) {
            return flatArray[i];
        }
    }
    return null;
}

const transcriptVoice = async(audioFile) => {
  
  const formData = new FormData();
  formData.append("file", audioFile);
  formData.append("model", "whisper-1");
  try {
    var myHeaders = new Headers();
    myHeaders.append("Cache-Control", "no-store");
    myHeaders.append(
      "Authorization",
      `Bearer ${process.env.REACT_APP_API_KEY}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    const response = await fetch(
      "https://api.openai.com/v1/audio/transcriptions",
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const transcript = await response.json();
    return transcript;
  } catch (error) {
    console.error(error);;
  }
}

  const recordVoice = async (blob) => {
    const startTime = new Date();

    const modifiedBlob = new Blob([blob], { type: "audio/ogg" });
    const url = URL.createObjectURL(modifiedBlob);
    const audioFile = new File([blob], "audiofile.ogg", {
      type: "audio/ogg",
    });
    let newUserMessage;
    let transcript;
    try {
      transcript = await transcriptVoice(audioFile);
      if (transcript?.text) {
        newUserMessage = {
          message: transcript.text,
          html: (
            <>
              <p style={{margin: "0"}}>{transcript.text}</p>
              {/* <AudioPlayer src={url} style={{ minWidth: "200px" }} controls /> */}
            </>
          ),
          sender: "user",
          direction: "outgoing",
          sentTime: getCurrentTime(),
          type: "voice",
        };
        setMessages((prevMessages) => [...prevMessages, newUserMessage]);
      try {
        let messagesToSend = [...messages];
        messagesToSend.push(newUserMessage);
        messagesToSend = messagesToSend.map(obj => {
          const { direction, html, type, ...rest } = obj;
          return rest;
        });  
        const data = await postUserMessage(messagesToSend, selectedMember, newInformation, username, password);
        if (data) {
          setNewInformation(data?.newInformation)
          streamTTS(data.output);
        }
      } catch (error) {
        makeNotif("error", error);
      }
      } else {
        makeNotif("error", "OpenAI STT API is not working correctly")
      }
    } catch (error) {
      makeNotif("error", "Error occurred while processing the voice file.");
    }
  };

  const streamTTS = async (text) => {
    var myHeaders = new Headers();
    myHeaders.append("Cache-Control", "no-store");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${process.env.REACT_APP_API_KEY}`
    );
    const raw = JSON.stringify({
      model: "tts-1",
      input: text,
      voice: selectedVoice,
      response_format: "mp3",
      speed: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const player = new SpeechPlayer({
        audio: audioRef.current,
        onPlaying: () => {},
        onPause: () => {},
        onChunkEnd: () => {},
        mimeType: "audio/mpeg",
      });
      await player.init();
      playerRef.current = player;
      const stream = await fetch(
        "https://api.openai.com/v1/audio/speech",
        requestOptions
      );
      for await (const chunk of streamAsyncIterable(
        stream.clone().body,
        stream.clone(),
        text
      )) {
        player.feed(chunk);
      }
    } catch (error) {
      console.error("Error streaming audio:", error);
    }
  };

  async function* streamAsyncIterable(stream, res, text) {
    const reader = stream.getReader();

    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          const blob = await res.blob();
          const url = URL.createObjectURL(blob);
          const newBotMessage = {
            message: text,
            html: (
              <div>
                <p style={{marginTop: "0"}}>{text}</p>
                <AudioPlayer src={url} style={{ minWidth: "200px" }} controls />
              </div>
            ),
            sender: "coach",
            sentTime: getCurrentTime(),
            type: "voice",
          };
          setMessages((prevMessages) => [...prevMessages, newBotMessage]);
          setRecording(false);
          return;
        }
        yield value;
      }
    } finally {
      reader.releaseLock();
    }
  }

  const saveMessages = () => {
    if (selectedCoach === "") {
      makeNotif("info", "Please select a coach before saving")
    } else if (!score) {
      makeNotif("info", "Please select a score before saving")
    }  else {
      const messagesToSend = newArray;
      setIsSaving(true);
      saveTranscription(messagesToSend, selectedMember, score, selectedCoach).then((res) => {
        makeNotif("info", res.message);
        setIDs((prev) => [...prev, res.id]);
        setIsSaving(false);
      }).catch((error) => {
        makeNotif("error", error);
        setIsSaving(false);
      });
    }
  }


  const handleSend = async (message) => {
    setTyping(true);
    const startTime = new Date();

    const newUserMessage = {
      message: message,
      html: (<p>{message}</p>),
      sender: "user",
      direction: "outgoing",
      sentTime: getCurrentTime(),
    };
    setMessages((prevMessages) => [...prevMessages, newUserMessage]);

    try {
      let messagesToSend = [...messages];
      messagesToSend.push(newUserMessage);
      messagesToSend = messagesToSend.map(obj => {
        const { direction, html, ...rest } = obj;
        return rest;
      });
      const data = await postUserMessage(messagesToSend, selectedMember, newInformation, username, password);
      setResponse(data);
      setNewInformation(data?.newInformation);
      // setInformation(data.information);
      // setStates(data.states);

      // const endTime = new Date();
      // const responseTime = endTime - startTime;
      // setResponseTimes((prevResponseTimes) => [
      //   ...prevResponseTimes,
      //   responseTime,
      // ]);

      // const newCoachMessage = {
      //   message: data.output,
      //   sender: "coach",
      //   sentTime: getCurrentTime(),
      // };
      // setMessages((prevMessages) => [...prevMessages, newCoachMessage]);
      streamTTS(data.output);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setTyping(false);
    }
  };

  function getRandomValueFromArray(array) {
    const randomIndex = Math.floor(Math.random() * array?.length);
    const randomValue = array[randomIndex];
    return randomValue;
  }

  const recordComment = async(blob) => {
    const modifiedBlob = new Blob([blob], { type: "audio/ogg" });
    const url = URL.createObjectURL(modifiedBlob);
    const audioFile = new File([blob], "audiofile.ogg", {
      type: "audio/ogg",
    });
    let newUserMessage;
    const transcript = await transcriptVoice(audioFile);
    if (transcript.text) {
      newUserMessage = {
        message: transcript.text,
        html: (
          <>
            <p style={{margin: "0"}}>{transcript.text}</p>
            {/* <AudioPlayer src={url} style={{ minWidth: "200px" }} controls /> */}
          </>
        ),
        sender: "comment",
        direction: "outgoing",
        sentTime: getCurrentTime(),
        type: "voice",
      };
      setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    } else {
      makeNotif("error", "OpenAI STT is not working correctly")
    }
  }

  const handleLogin = async(e) => {
    e.preventDefault();
    await fetchData();
  }
  
  const fetchData = async () => {
    try {
      const data = await fetchCoachData(username, password);
      if (data.message !== "Incorrect credentials") {
        Cookies.set("username", username);
        Cookies.set("password", password);
        setInvalidCredentials(false);
        setResponse(data);
        setType(data.type);
        setVersion(data.version);
        setMembers(data.members);
        const lifeEvents = Object.keys(data.members);
        setLifeEvents(lifeEvents);
        setSelectedLifeEvent(lifeEvents[0]);
        const member = data.members[lifeEvents[0]][0];
        setCoachNames(data.coachNames);
        if (member.sex === "m") {
          const voice = getRandomValueFromArray(maleVoices);
          setSelectedVoice(voice);
        } else if (member.sex === "f") {
          const voice = getRandomValueFromArray(femaleVoices);
          setSelectedVoice(voice);
        }
        setSelectedMember(member.name)
        setModalIsOpen(false);
        } else {
        setInvalidCredentials(true);
        setModalIsOpen(true);
        Cookies.remove("username");
        Cookies.remove("password");
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setModalIsOpen(true);
      }
    }
  };
  useEffect(() => {
    fetchData();
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true }).catch(() => makeNotif("error", "You need to give microphone access to be able to record voice!"));
    } else {
      makeNotif("info", "Audio recording not supported in this browser")
    }
  }, []);

  useEffect(() => {
    setNewInformation("");
    const member = searchByName(selectedMember);
    let voice;
    if (member?.sex === "m") {
      voice = getRandomValueFromArray(maleVoices);
    } else if (member?.sex === "f") {
      voice = getRandomValueFromArray(femaleVoices);
    }
    setSelectedVoice(voice);
  }, [selectedMember, selectedLifeEvent])

  return (
    <div className="container">
      <Modal
        isOpen={modalIsOpen}
        style={modalStyle}
      >
        <form className="login-form" onSubmit={handleLogin}>
          <input type="text" name="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)}/>
          <input type="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
          {invalidCredentials && <p style={{color: "red", margin: 0}}>Invalid credentials</p>}
          <button className="button">Login</button>
        </form>
      </Modal>
      <audio controls ref={audioRef} style={{display: "none"}} />
      <div className="members item">
        { coachNames?.length > 0 && (
          <FormControl size="small">
          <label>Coach</label>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedCoach}
            placeholder="Select a coach"
            onChange={(e) => setSelectedCoach(e.target.value)}
          >
            {coachNames.map((coach, idx) => (
              <MenuItem key={idx} value={coach}>
                <div className="menu-item">
                  <p style={{ fontWeight: "bold" }}>{coach}</p>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        )}
        {lifeEvents?.length > 0 && (
          <FormControl size="small">
            <label>Life Event</label>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedLifeEvent}
              onChange={handleLifeEventChange}
            >
              {lifeEvents.map((lifeEvent, idx) => (
                <MenuItem key={idx} value={lifeEvent}>
                  <div className="menu-item">
                    <p style={{ fontWeight: "bold" }}>{lifeEvent}</p>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {members[selectedLifeEvent]?.length > 0 && (
          <FormControl size="small"  style={{marginTop: "8px"}}>
            <label>Member</label>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedMember}
            onChange={handleMemberChange}
          >
            {members[selectedLifeEvent].map((member, idx) => (
              <MenuItem key={idx} value={member.name}>
                <div className="menu-item">
                  <p style={{ fontWeight: "bold" }}>{member.name}</p>
                  <p>{member.reason}</p>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        )}
        <label htmlFor="score-input">How realistic was the conversation?</label>
        <div className="radio-container">
          <div className="radio-label">
            <label htmlFor="score-1">1</label>
            <input
              type="radio"
              id="score-1"
              name="score"
              value="1"
              onChange={handleRadioChange}
              checked={score === "1"}
              />
          </div>
          <div className="radio-label">
            <label htmlFor="score-2">2</label>
            <input
              type="radio"
              id="score-2"
              name="score"
              value="2"
              onChange={handleRadioChange}
              checked={score === "2"}
            />
          </div>
          <div className="radio-label">
            <label htmlFor="score-3">3</label>
            <input
              type="radio"
              id="score-3"
              name="score"
              value="3"
              onChange={handleRadioChange}
              checked={score === "3"}
            />
          </div>
          <div className="radio-label">
            <label htmlFor="score-4">4</label>
            <input
              type="radio"
              id="score-4"
              name="score"
              value="4"
              onChange={handleRadioChange}
              checked={score === "4"}
            />
          </div>
          <div className="radio-label">
            <label htmlFor="score-5">5</label>
            <input
              type="radio"
              id="score-5"
              name="score"
              value="5"
              onChange={handleRadioChange}
              checked={score === "5"}
              />
            </div>
        </div>
        <button className="button" onClick={saveMessages} disabled={isSaving}>{isSaving ? "Saving. Please wait" : "Save Conversation"}</button>
      </div>
      <div className="chat-box item">
        <MainContainer>
          <ChatContainer className="chat-container">
            <MessageList
              typingIndicator={
                typing && <TypingIndicator style={{ backgroundColor: "#fff0" }} content="Member is typing" />
              }
            >
                {messages.map((message, i) => (
                  <React.Fragment key={i}>
                    <Message model={message} className={message.sender}>
                      {message.type === "voice" && (
                        <Message.CustomContent>
                          {message.html}
                        </Message.CustomContent>
                      )}
                    </Message>
                    <p className={`time${message.sender}`}>{message.sentTime}</p>
                  </React.Fragment>
                ))}
            </MessageList>
            <div
              as="MessageInput"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <MessageInput
                placeholder="Type message here"
                onSend={handleSend}
                className="message-input"
                style={{
                  flexGrow: 1,
                  borderTop: 0,
                  flexShrink: "initial",
                  paddingBottom: "24px",
                }}
              />
              <div className="voice-recorder">
                <AudioRecorder
                  onRecordingComplete={recordVoice}
                  onNotAllowedOrFound={handleMicrophoneAccess}
                />
              </div>
            </div>
          </ChatContainer>
        </MainContainer>
      </div>
      <div className="button-container item">
            <button className="button" onClick={() => window.open(`/information/${selectedMember}/new:${newInformation}`,'_blank')}>Member Information</button>
            <button className="button" onClick={() => window.open(`/transcription`,'_blank')}>Chat History</button>
            <button className="button" onClick={() => window.open(`/filtering`,'_blank')}>Filtering Page</button>
            <b style={{margin: 0}}>Record Comment</b>
            <AudioRecorder
                  onRecordingComplete={recordComment}
                  onNotAllowedOrFound={handleMicrophoneAccess}
            />
            <div className="id-list">
              {IDs?.length > 0 && 
                <>
                  <b color="black">Conversation IDs:</b>
                  {IDs.map((id, idx) => (
                    <p>{id}</p>
                    ))}
                </>
              }
            </div>
      </div>
    </div>
  );
}

export default Chatbox;
